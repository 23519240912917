import { CabToggleChip, CabButton, CabIcon, CabSwitch, CabTooltip } from "@CabComponents";
import Scheduler from "./Calendar";
import {
  CalDateRange, Calendar, DisplayCalendarsDict, Leader, Meetings, MeetingSlot,
  UICalendarEventConsolidated, Meeting, MeetingStatus,
} from "../../../store";
import { ReactElement, useMemo, useState } from "react";
import { styled, Box, Divider, Typography, Menu, MenuItem, ListItemText, SxProps } from "@mui/material";
import { SlotInfo, View as RBCView, Views as RBCViews } from "react-big-calendar";
import { combineSlots, nonReadDisableText, TimeZone } from "../../../utils/scheduleUtils";
import { useCabinetText } from "../../../CabinetContext";
import colors from "../../../colors";
import { DateTime } from "luxon";
import { isMobile } from "../../../utils/screenSizeUtils";
import CabSpinner from "@CabComponents/CabSpinner";
import { IoWarning, IoCalendarOutline, IoChevronDown, IoLockClosedOutline } from "react-icons/io5";
import EditParticipantsModal from "../EditParticipantsModal";


export interface CalendarSchedulerProps {
  leaderHasAssociations: boolean;
  userHasGrant: boolean;
  selectedLeaders: Leader[];
  displayCalendars: DisplayCalendarsDict;
  calendars: Calendar[];
  onCalendarClick?: (calendarId: string) => void;
  openAssociationModal?: () => void;
  openAdditionalCalendarsModal?: () => void;
  openAccountManagementModal?: () => void;
  currentDateRangeInfo: CalDateRange | null;
  loadingCalendars: boolean;
  handleOpenMeeting?: (meetingId: number) => void;
  onOpenPollResults?: (meetingId: number) => void;
  selectedSlots: MeetingSlot[];
  recurringSlots: MeetingSlot[];
  handleSlotsCreated?: (info: SlotInfo, isExcluded?: boolean) => void;
  handleEditSlot?: (eventId: string, start: Date, end: Date, isExcluded: boolean) => void;
  onDateChange: (range: CalDateRange) => void;
  calendarTimezoneSelected: TimeZone | undefined;
  handleCalendarTimezoneSelected?: (timezone: TimeZone) => void;
  secondaryTimezonesSelected: (TimeZone | undefined)[];
  handleSecondaryTimezoneSelected?: (idx: number, timezone: TimeZone) => void;
  coloredEvents: UICalendarEventConsolidated[];
  handleDeleteSlots?: (slots: MeetingSlot[]) => void;
  currentMeetingId: number | undefined;
  currentMeetingDurationMinutes?: number;
  isPoll?: boolean
  meetings: Meetings
  handleDuplicateMeeting?: (meetingId: number) => void
  handleDeleteMeeting?: (meetingId: number) => void
  handleShareMeeting?: (meetingId: number) => void
  handleCopyLink?: (meetingId: number) => void
  showSecondaryTimezone?: boolean;
  hasMultiTimezone?: boolean;
  onToggleMultiTimezone?: () => Promise<void>;
  onToggleShowAllRecurringSlots?: () => void;
  showAllRecurringSlots?: boolean;
  meetingPreventDoubleBooking?: boolean;
  showCanceledDeclinedMeetings?: boolean;
  onToggleCanceledDeclinedMeetings?: () => void;
  openUpgradeModal?: () => void;
  additionalCalendarIds: number[];
  onUpdateAdditionalCalendar?: (additionalCalendars: number[]) => void
  hideHeader?: boolean;
  hideViewOptionsPicker?: boolean;
  hideTodayButton?: boolean;
  hideViewPicker?: boolean;
  noCalendarBorder?: boolean;
  hideResourceHeader?: boolean;
  defaultView?: RBCView;
  sx?: SxProps;
  newMeeting: Partial<Meeting> | null;
  interactive?: boolean;
  hasMultiLeaderCalendarView?: boolean;
  allowedDates?: DateTime[];
  numHiddenCalendarTeammates?: number;
}

const MessageContainer = styled("span", { label: "MessageContainer" })({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "42px",
});

export const CalendarScheduler = ({ leaderHasAssociations, userHasGrant, displayCalendars,
  onCalendarClick, openAssociationModal, openAccountManagementModal, currentDateRangeInfo,
  handleCalendarTimezoneSelected, calendarTimezoneSelected, secondaryTimezonesSelected, handleSecondaryTimezoneSelected,
  handleOpenMeeting, handleSlotsCreated, handleEditSlot, onDateChange, coloredEvents, selectedSlots,
  selectedLeaders, handleDeleteSlots, currentMeetingId, loadingCalendars,
  currentMeetingDurationMinutes, calendars, isPoll, meetings, handleDuplicateMeeting,
  handleDeleteMeeting, handleShareMeeting, handleCopyLink, showSecondaryTimezone, recurringSlots, hasMultiTimezone,
  onToggleMultiTimezone, showAllRecurringSlots, onToggleShowAllRecurringSlots, meetingPreventDoubleBooking,
  showCanceledDeclinedMeetings, onToggleCanceledDeclinedMeetings, openAdditionalCalendarsModal, hideHeader,
  openUpgradeModal, additionalCalendarIds, onUpdateAdditionalCalendar, hideViewOptionsPicker, hideTodayButton,
  hideViewPicker, noCalendarBorder, hideResourceHeader, defaultView, sx, newMeeting, interactive,
  hasMultiLeaderCalendarView, allowedDates, onOpenPollResults, numHiddenCalendarTeammates
}: CalendarSchedulerProps): ReactElement => {
  const [showPendingSlots, setShowPendingSlots] = useState(true);
  const [condensePendingSlots, setCondensePendingSlots] = useState(false);
  const [showAllDayEvents, setShowAllDayEvents] = useState(true);
  const [loadingViewOptions, setLoadingViewOptions] = useState(false);
  const [showMultiLeaderColumns, setShowMultiLeaderColumns] = useState(false);
  const [calendarView, setCalendarView] = useState<RBCView>(defaultView || RBCViews.WORK_WEEK);
  const [editParticipantsModalOpen, setEditParticipantsModalOpen] = useState(false);

  const [noCalsMessage, noneSelectedMessage] = useCabinetText([
    'no-calendars-warning', 'schedule-no-teammember-selected',
  ]);

  const calendarsDict: { [calendarId: string]: Calendar } = {};
  calendars.forEach(calendar => {
    calendarsDict[calendar.calendar_id] = calendar;
  });

  const calendarMap: { [key: number]: Calendar } = Object.fromEntries(calendars.map((cal => [cal.id, cal])));

  const [viewOptionsAnchorEl, setViewOptionsAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(viewOptionsAnchorEl);
  const handleViewOptionsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setViewOptionsAnchorEl(event.currentTarget);
  };
  const handleViewOptionsClose = () => {
    setViewOptionsAnchorEl(null);
  };

  const handleTogglePendingSlots = () => {
    setShowPendingSlots(!showPendingSlots);
  };

  const handleToggleCondensePendingSlots = () => {
    setCondensePendingSlots(!condensePendingSlots);
  };

  const handleToggleAllDayEvents = () => {
    setShowAllDayEvents(!showAllDayEvents);
  };

  const handleToggleMultiTimezone = onToggleMultiTimezone && (async () => {
    setLoadingViewOptions(true);
    await onToggleMultiTimezone();
    setLoadingViewOptions(false);
  });

  const handleToggleMultiLeaderColumns = () => {
    setShowMultiLeaderColumns(!showMultiLeaderColumns);
  };

  const removeAdditionalCalendar = onUpdateAdditionalCalendar && ((calAccessId: number) => {
    const cals = Object.values(displayCalendars);
    const newAdditionalCalendars = additionalCalendarIds
      .map(id => cals.find(c => c.id === id))
      .filter((cal): cal is DisplayCalendarsDict[string] => !!cal && cal.calendarAccessId !== calAccessId);

    const newAdditionalCalendarAccessIds = newAdditionalCalendars
      .map(cal => cal?.calendarAccessId)
      .filter((accessId): accessId is number => accessId !== undefined);

    onUpdateAdditionalCalendar(newAdditionalCalendarAccessIds);
  });

  const meetingSlotsToShow = useMemo(() => {
    const slotsToShow = showPendingSlots
      ? selectedSlots.filter(s => (
        meetings[s.meeting]?.status === MeetingStatus.PENDING || s.meeting === currentMeetingId || s.meeting === -1
      ))
      : selectedSlots.filter(s => s.meeting === currentMeetingId || s.meeting === -1);

    if (condensePendingSlots) {
      return combineSlots(slotsToShow);
    } else {
      return slotsToShow;
    }
  }, [condensePendingSlots, currentMeetingId, selectedSlots, showPendingSlots, meetings]);

  const eventsToShow = showAllDayEvents
    ? coloredEvents
    : coloredEvents.filter(s => !s.allDay);

  const sideBySideMap = useMemo(() => {
    const leaderMap = selectedLeaders.map((leader) => {
      return {
        resourceId: leader.id.toString(),
        columnTitle: `${leader?.first_name} ${leader?.last_name}`,
      };
    });
    const additionalCalendarMap = additionalCalendarIds.map((calId) => {
      const cal = calendars.find(c => c.id === calId);
      return { resourceId: cal?.owner_email || calId.toString(), columnTitle: cal ? cal.summary : calId.toString() };
    });
    return [...leaderMap, ...additionalCalendarMap];
  }, [selectedLeaders, additionalCalendarIds, calendars]);

  return (
    <Box id="CalendarScheduler" display="flex" flexDirection="column" flex={1} sx={sx}>
      {(!hideHeader && leaderHasAssociations && userHasGrant) && (
        <Box display='flex' width="100%" justifyContent="space-between" alignItems="start" gap={1}
          paddingTop={1} paddingBottom={1}
        >
          {removeAdditionalCalendar && onCalendarClick && (
            <Box display="flex" flexWrap="wrap" gap={1} sx={{ paddingTop: 0.5 }}>
              {Object.keys(displayCalendars)
                // if we have access to this calendar or it is associated with a shared exec
                .filter(calendarId => Boolean(calendarsDict[calendarId]) ||
                  displayCalendars[calendarId].leaders
                    .map(lId => selectedLeaders.find(leader => leader.id === lId))
                    .some(leader => leader?.is_shared)
                )
                .map((calendarId) => {
                  const hasAccess = calendars.some(
                    cal => cal.calendar_id === calendarId && cal.readable
                  );
                  const isSelected = displayCalendars[calendarId] ? displayCalendars[calendarId].display : true;
                  const calendar = displayCalendars[calendarId];
                  const isAdditional = additionalCalendarIds.includes(calendar.id);

                  const calendarName = calendar?.additionalCalendarEmail?.name
                    || calendar.name || calendarsDict[calendarId]?.owner_email || 'Loading...';
                  const calendarAccessMessage = nonReadDisableText(
                    calendar.canEdit, calendar.readable, calendar.provider, calendar.freeBusy
                  );
                  const color = calendar.color;
                  const calendarAccessId = calendar.calendarAccessId;
                  const onDelete = isAdditional && calendarAccessId
                    ? () => removeAdditionalCalendar(calendarAccessId)
                    : undefined;

                  return (
                    <CabToggleChip
                      key={calendarId}
                      translateColor={true}
                      label={calendarName}
                      onDelete={onDelete}
                      chipColor={color}
                      onClick={(() => onCalendarClick(calendarId))}
                      selected={isSelected}
                      icon={hasAccess
                        ? undefined
                        : <CabIcon Icon={IoWarning} color="white" alt={calendarAccessMessage} />}
                      title={!hasAccess ? calendarAccessMessage : ''}
                    />
                  );
                })}
              {!!numHiddenCalendarTeammates && (
                <Typography
                  fontStyle="italic"
                  color={colors.black700}
                  onClick={() => setEditParticipantsModalOpen(true)}
                  sx={{ cursor: 'pointer' }}
                >
                  {numHiddenCalendarTeammates} teammate{numHiddenCalendarTeammates > 1 ? "s'" : "'s"} calendars hidden
                </Typography>
              )}

              {loadingCalendars && (
                <Box>
                  <CabSpinner scale={1} />
                </Box>
              )}
            </Box>
          )}

          <Box display="flex" gap={1}>
            {openAdditionalCalendarsModal && (
              <CabButton
                icon={<CabIcon Icon={IoCalendarOutline} />}
                buttonType="text"
                onClick={openAdditionalCalendarsModal}
                size="small"
                sx={{
                  border: `1px solid ${colors.black200}`,
                  color: colors.black900,
                  padding: 1, paddingLeft: 1.5,
                  minWidth: 155,
                }}
              >
                Other Calendars
              </CabButton>
            )}

            {!hideViewOptionsPicker && (
              <>
                <CabButton
                  buttonType="text"
                  id="view-options-button"
                  aria-controls={open ? 'view-options-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleViewOptionsClick}
                  endIcon={<CabIcon Icon={IoChevronDown} sx={{ width: '14px' }} />}
                  sx={{
                    border: `1px solid ${colors.black200}`,
                    color: colors.black900,
                    padding: 1, paddingLeft: 1.5,
                    minWidth: 130,
                  }}
                  size="small"
                >
                  View Options
                </CabButton>
                <Menu
                  id="view-options-menu"
                  anchorEl={viewOptionsAnchorEl}
                  open={open}
                  onClose={handleViewOptionsClose}
                  MenuListProps={{ 'aria-labelledby': 'view-options-button' }}
                >
                  <MenuItem onClick={handleTogglePendingSlots} dense>
                    <CabSwitch
                      checked={showPendingSlots} onChange={handleTogglePendingSlots}
                      sx={{ marginRight: 2 }}
                    />
                    <ListItemText>
                      Show Pending Slots
                    </ListItemText>
                  </MenuItem>
                  <MenuItem onClick={handleToggleCondensePendingSlots} dense>
                    <CabSwitch
                      checked={condensePendingSlots} onChange={handleToggleCondensePendingSlots}
                      sx={{ marginRight: 2 }}
                    />
                    <ListItemText>
                      Condense Pending Slots
                    </ListItemText>
                  </MenuItem>
                  <MenuItem onClick={onToggleShowAllRecurringSlots} dense>
                    <CabSwitch
                      checked={showAllRecurringSlots}
                      onChange={onToggleShowAllRecurringSlots}
                      sx={{ marginRight: 2 }}
                    />
                    <ListItemText>
                      Show All Recurring Slots
                    </ListItemText>
                  </MenuItem>
                  <MenuItem onClick={handleToggleAllDayEvents} dense>
                    <CabSwitch
                      checked={showAllDayEvents} onChange={handleToggleAllDayEvents}
                      sx={{ marginRight: 2 }}
                    />
                    <ListItemText>
                      Show All Day Events
                    </ListItemText>
                  </MenuItem>
                  <MenuItem onClick={onToggleCanceledDeclinedMeetings} dense>
                    <CabSwitch
                      checked={showCanceledDeclinedMeetings} onChange={onToggleCanceledDeclinedMeetings}
                      sx={{ marginRight: 2 }}
                    />
                    <ListItemText>
                      Show Declined Events
                    </ListItemText>
                  </MenuItem>
                  <MenuItem
                    onClick={hasMultiTimezone ? handleToggleMultiTimezone : openUpgradeModal}
                    disabled={loadingViewOptions} dense>
                    <CabSwitch
                      checked={showSecondaryTimezone}
                      onChange={handleToggleMultiTimezone}
                      disabled={!hasMultiTimezone}
                      sx={{ marginRight: 2 }}
                    />
                    <ListItemText>
                      <Box display='flex' gap={1}>
                        <Typography variant='body2' color={!hasMultiTimezone ? colors.black600 : 'unset'}>
                          Show Multiple Timezones
                        </Typography>
                        {!hasMultiTimezone && (
                          <CabIcon
                            Icon={IoLockClosedOutline}
                            alt='Locked'
                            slot='end'
                            sx={{ fontSize: 19 }}
                          />
                        )}
                      </Box>
                    </ListItemText>
                  </MenuItem>
                  {hasMultiLeaderCalendarView && (
                    <CabTooltip wrapWithSpan
                      title={calendarView === RBCViews.DAY ? 'This option is always on for Day View' : ''}
                    >
                      <MenuItem
                        onClick={handleToggleMultiLeaderColumns}
                        disabled={calendarView === RBCViews.DAY}
                        dense
                      >
                        <CabSwitch
                          checked={showMultiLeaderColumns || calendarView === RBCViews.DAY}
                          disabled={calendarView === RBCViews.DAY}
                          onChange={handleToggleMultiLeaderColumns}
                          sx={{ marginRight: 2 }}
                        />
                        <ListItemText>
                          Split Teammate View
                        </ListItemText>
                      </MenuItem>
                    </CabTooltip>
                  )}
                </Menu>
              </>
            )}
          </Box>
        </Box>
      )}

      <Divider light={true} />

      <Box display="flex" flex={1} flexDirection="column" paddingTop={1.5}>
        {(leaderHasAssociations && userHasGrant)
          ? (
            <Scheduler
              calendarMap={calendarMap}
              useMultiTimezone={showSecondaryTimezone}
              slotsSelected={meetingSlotsToShow}
              recurringSlots={recurringSlots}
              onSlotSelected={handleSlotsCreated}
              onSlotEdited={handleEditSlot}
              onDateChange={onDateChange}
              events={eventsToShow}
              currentDateRangeInfo={currentDateRangeInfo}
              calendarTimezoneSelected={calendarTimezoneSelected}
              onCalendarTimezoneSelected={handleCalendarTimezoneSelected}
              secondaryTimezonesSelected={secondaryTimezonesSelected}
              onSecondaryTimezoneSelected={handleSecondaryTimezoneSelected}
              onOpenMeeting={handleOpenMeeting}
              onOpenPollResults={onOpenPollResults}
              onDeleteSlots={handleDeleteSlots}
              currentMeetingId={currentMeetingId}
              currentMeetingDurationMinutes={currentMeetingDurationMinutes}
              isPoll={isPoll}
              meetings={meetings}
              onDuplicateMeeting={handleDuplicateMeeting}
              onDeleteMeeting={handleDeleteMeeting}
              onShareMeeting={handleShareMeeting}
              onCopyLink={handleCopyLink}
              meetingPreventDoubleBooking={meetingPreventDoubleBooking}
              sideBySideMap={sideBySideMap}
              hideTodayButton={hideTodayButton}
              hideViewPicker={hideViewPicker}
              noCalendarBorder={noCalendarBorder}
              hideResourceHeader={hideResourceHeader}
              newMeeting={newMeeting}
              interactive={interactive}
              showMultiLeaderColumns={showMultiLeaderColumns}
              allowedDates={allowedDates}
              calendarView={calendarView}
              onCalendarViewChange={setCalendarView}
            />
          )
          : (
            <MessageContainer>
              {
                selectedLeaders.length > 0
                  ? (
                    userHasGrant
                      ? (<>
                        <Typography variant="body1" marginBottom={3}>
                          {noCalsMessage}
                        </Typography>
                        <CabButton buttonType="tertiary" color="primary" onClick={openAssociationModal}>
                          Attach a Calendar
                        </CabButton>
                      </>
                      )
                      : (<>
                        <CabIcon Icon={IoCalendarOutline} sx={{ fontSize: 60 }} />
                        <Typography variant="body1" sx={{ margin: "24px 0" }}>
                          You're not currently signed into any calendar provider.
                          Sign in so we can help you schedule meetings faster!
                        </Typography>
                        {!isMobile() && (
                          <CabButton buttonType="tertiary" color="primary" onClick={openAccountManagementModal}>
                            Connect Your Calendars
                          </CabButton>
                        )}
                      </>)
                  )
                  : (
                    <Typography variant="body1">
                      {noneSelectedMessage}
                    </Typography>
                  )
              }
            </MessageContainer>
          )
        }
      </Box>

      {editParticipantsModalOpen && currentMeetingId && (
        <EditParticipantsModal
          open={editParticipantsModalOpen}
          onClose={() => setEditParticipantsModalOpen(false)}
          meetingId={currentMeetingId}
        />
      )}
    </Box>
  );
};

export default CalendarScheduler;