import { useMemo } from 'react';
import { DateLocalizer } from 'react-big-calendar';
// @ts-expect-error no types exported
import TimeGrid from 'react-big-calendar/lib/TimeGrid';

function CustomWorkWeek({
  date,
  localizer,
  max = localizer.endOf(new Date(), 'day'),
  min = localizer.startOf(new Date(), 'day'),
  scrollToTime,
  ...props
}: { date: Date; localizer: DateLocalizer; max: Date; min: Date; scrollToTime: boolean }) {
  const currRange = useMemo(
    () => CustomWorkWeek.range(date, { localizer }),
    [date, localizer]
  );

  return (
    <TimeGrid
      date={date}
      eventOffset={15}
      localizer={localizer}
      max={max}
      min={min}
      range={currRange}
      scrollToTime={scrollToTime}
      enableAutoScroll={true}
      {...props}
    />
  );
}

CustomWorkWeek.range = (date: Date, { localizer }: { localizer: DateLocalizer }) => {
  // @ts-expect-error this wants an argument but does not seem to have issues without it
  const firstOfWeek = localizer.startOfWeek();
  const start = localizer.startOf(date, 'week', firstOfWeek) as Date;
  const end = localizer.endOf(date, 'week', firstOfWeek) as Date;

  const weekRange = localizer.range(start, end) as Date[];
  return weekRange.slice(1, 6);
};

CustomWorkWeek.navigate = (date: Date, action: unknown, { localizer}: { localizer: DateLocalizer }) => {
  return date;
};

CustomWorkWeek.title = (date: Date) => {
  return `Week of: ${date.toLocaleDateString()}`;
};

export default CustomWorkWeek;